#App {
    font-family: 'Roboto', sans-serif;
}

.adm-list-item-content-prefix {
    margin-top: 4px;
}

.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
}

.text-center {
    text-align: center;
}
